export const links = [
  {
    name: "Programmes",
    submenu: true,
    hasCategory: true,
    sublinks: [
      { name: "Finance", link: "/Finance" },
      { name: "Gas and Oil", link: "/Gas and Oil" },
      { name: "Human Resources", link: "/Human Resources" },
      { name: "Law", link: "/Law" },
      { name: "Management", link: "/Management" },
      { name: "Operations Management", link: "/Operations Management" },
      { name: "Sales and Marketing", link: "/Sales and Marketing" },
      { name: "Secretarial Pratices", link: "/Secretarial Pratices" },
      { name: "Public Relations", link: "/Public Relations" },
    ],
  },
  {
    name: "Info",
    submenu: true,
    hasCategory: false,
    sublinks: [
      { name: "Visa Applications", link: "/infomation/visa" },
      { name: "Course Alocation", link: "/infomation/programme/alocation" },
      { name: "Terms And Condition", link: "/infomation/Terms" },
      { name: "Policies", link: "/infomation/policies" },
      { name: "Important Documents", link: "/infomation/manager/director" },
      // { name: "FAQ", link: "/infomation/faq" },
    ],
  },
  {
    name: "About",
    submenu: true,
    hasCategory: false,
    sublinks: [
      { name: "About Us", link: "/about" },
      { name: "Our Team", link: "/about/team" },
      // { name: "Training Consultants", link: "/about/training/consultant" },
      { name: "Venues", link: "/about/vanues" },
      // { name: "Testimonials", link: "/" },
    ],
  },
];

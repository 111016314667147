import React, { forwardRef, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Logo from "../../../assets/svg/Logo.svg";
import NavLinks from "./NavLinks";
import WithClickOutside from "../../WithClickOutside";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import getLanguage from "../../../utils/getLanguage";
import i18next from "i18next";
import UkFlag from "../../../assets/photo/ukFlag.jpg";
import FranceFlag from "../../../assets/photo/FranceFlag.png";
import LinkedComapny from "../../../assets/photo/linked-company-Logo.png";
import "./style.css";

const Navbar = forwardRef(({ open, setOpen }, ref) => {
  const { t } = useTranslation();

  const lan = getLanguage();
  const lanRef = useRef();

  const handleLanChange = (value) => {
    i18next.changeLanguage(value);
  };
  useEffect(() => {
    if (lanRef.current) {
      lanRef.current.value = lan;
    }
  });

  return (
    <>
      <nav className="bg-[#003d88] border-gray-200 fixed z-50 w-full text-white xpy-1">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-[1100px] p-4">
          <div className="flex items-start justify-start">
            <div className="flex flex-col">
              <Select
                defaultValue={lan}
                ref={lanRef}
                onChange={handleLanChange}
                style={{ width: 130 }}
              >
                <Select.Option value="en" className="capitalize">
                  <p className="flex items-center space-x-2">
                    <img src={UkFlag} alt="" className="w-4 h-4" />
                    <span>English</span>
                  </p>
                </Select.Option>
                <Select.Option value="fr" className="capitalize">
                  <p className="flex items-center space-x-2">
                    <span>
                      {" "}
                      <img src={FranceFlag} alt="" className="w-4 h-4" />
                    </span>
                    <span>Français</span>
                  </p>
                </Select.Option>
              </Select>
            </div>
          </div>
          <span className="flex items-center rtl:space-x-reverse md:ml-10">
            <div
              className="hidden md:flex items-center"
              style={{ color: "hsla(0,0%,100%,.8)" }}
            >
              <a href="mailto:info@nctar.org" className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="mr-3 h-5 w-5"
                >
                  <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                  <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                </svg>
                <p className="uppercase pr-4 text-sm">
                  {t("Get in Touch")} {"   "}{" "}
                </p>
              </a>
            </div>
            <div className="hidden md:flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="mr-3 h-5 w-5"
              >
                <path
                  fillRule="evenodd"
                  d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                  clipRule="evenodd"
                />
              </svg>
              <span
                className="self-center text-sm md:text-lg font-medium font-[Exo] whitespace-nowrap pr-4"
                style={{ color: "hsla(0,0%,100%,.8)" }}
              >
                +254 720 530 113
              </span>
            </div>
            <span>
              <a
                href="https://pinnacleperformanceltd.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={LinkedComapny} alt="" className="w-24 object-cover" />
              </a>
            </span>
          </span>
        </div>
      </nav>
      <nav
        className={`bg-white border-gray-200 fixed z-50 w-full text-gray-900 shadow ${
          open ? "mt-0" : "mt-14"
        }`}
      >
        <div className="max-w-[1100px] mx-auto">
          <div className="flex items-center font-medium justify-around">
            <div className="z-50 p-2 md:w-auto w-full flex justify-between">
              <Link to={"/"} className="flex items-center">
                <img
                  src={Logo}
                  alt="logo"
                  className="md:cursor-pointer h-16 md:h-14"
                />
                <span className="md:hidden font-semibold font-sans">
                  NCTAR Global Consultants Ltd
                </span>
              </Link>
              {open && (
                <div
                  className="text-3xl md:hidden"
                  // onClick={() => setOpen(!open)}
                >
                  <ion-icon name={`${open ? "close" : "menu"}`}></ion-icon>
                </div>
              )}
            </div>
            <ul className="md:flex hidden uppercase items-center gap-8 font-[Poppins]">
              <li>
                <Link to="/" className="py-7 px-3 inline-block">
                  {t("Home")}
                </Link>
              </li>
              <NavLinks />
              <li>
                <Link
                  to="/programme/calendar"
                  className="py-7 px-3 inline-block"
                >
                  {t("Course Calendar")}
                </Link>
              </li>
              <li>
                <Link to="/contact" className="py-7 px-3 inline-block">
                  {t("Contact")}
                </Link>
              </li>
            </ul>
            {/* Mobile nav */}
            <ul
              ref={ref}
              className={`
        md:hidden bg-white fixed w-full top-0 overflow-y-auto bottom-0 py-24 pl-4 z-10
        duration-500 ${open ? "left-0" : "left-[-100%]"}
        `}
            >
              <li>
                <Link to="/" className="py-2 px-3 inline-block font-bold">
                  {t("Home")}
                </Link>
              </li>
              <NavLinks />
              <li>
                <Link
                  to="/programme/calendar"
                  className="py-2 px-3 inline-block font-bold"
                >
                  {t("Course Calendar")}
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="py-2 px-3 inline-block font-bold"
                >
                  {t("Contact")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="header-bottom-actions">
        <div className="flex text-gray-800 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="mr-3 h-5 w-5"
          >
            <path
              fillRule="evenodd"
              d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
              clipRule="evenodd"
            />
          </svg>
          <span className="self-center text-sm md:text-lg font-medium font-[Exo] whitespace-nowrap pr-4 text-gray-800">
            +254 720 530 113
          </span>
        </div>
        <button
          class="header-bottom-actions-btn"
          data-nav-open-btn
          aria-label="Open Menu"
          onClick={() => setOpen(!open)}
        >
          <ion-icon name="menu-outline"></ion-icon>
          <span>Menu</span>
        </button>
      </div>
    </>
  );
});

export default WithClickOutside(Navbar);

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { links } from "./Mylinks";
import { useTranslation } from "react-i18next";

const NavLinks = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [heading, setHeading] = useState("");

  const translatedLinks = links.map((linkGroup) => ({
    ...linkGroup,
    name: t(linkGroup.name),
    sublinks: linkGroup.sublinks.map((sublink) => ({
      ...sublink,
      name: t(sublink.name),
    })),
  }));

  return (
    <>
      {translatedLinks.map((link) => (
        <div>
          <div className="px-3 text-left md:cursor-pointer group">
            <h1
              className="py-2 flex justify-between items-center md:pr-0 pr-5 group"
              onClick={() => {
                heading !== link.name ? setHeading(link.name) : setHeading("");
              }}
            >
              {link.name}
              <span className="text-xl md:hidden inline">
                <ion-icon
                  name={`${
                    heading === link.name ? "chevron-up" : "chevron-down"
                  }`}
                ></ion-icon>
              </span>
              <span className="text-xl md:mt-1 md:ml-2  md:block hidden group-hover:rotate-180 group-hover:-mt-2">
                <ion-icon name="chevron-down"></ion-icon>
              </span>
            </h1>
            {link.submenu && (
              <>
                {link.hasCategory ? (
                  <div>
                    <div className="absolute top-14 hidden group-hover:md:block hover:md:block z-30">
                      <div className="bg-white p-5 grid grid-cols-1 gap-2">
                        {link.sublinks.map((mysublinks) => (
                          <li
                            className="text-sm text-[#003d97] my-2.5"
                            onClick={() =>
                              navigate(`/programme${mysublinks.link}`)
                            }
                          >
                            {mysublinks.name}
                          </li>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="absolute top-14 hidden group-hover:md:block hover:md:block z-30">
                      <div className="bg-white p-5 grid grid-cols-1 gap-2">
                        {link.sublinks.map((mysublinks) => (
                          <li className="text-sm text-[#003d97] my-2.5">
                            <Link
                              to={mysublinks.link}
                              className="hover:text-gray-9000"
                            >
                              {mysublinks.name}
                            </Link>
                          </li>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          {/* Mobile menus */}
          <div
            className={`
            ${heading === link.name ? "md:hidden" : "hidden"}
          `}
          >
            {link.sublinks.map((slinks) => (
              <ul>
                {link.hasCategory ? (
                  <li
                    className="py-3 pl-14"
                    onClick={() => navigate(`/programme${slinks.link}`)}
                  >
                    {slinks.name}
                  </li>
                ) : (
                  <li className="py-3 pl-14">
                    <Link to={slinks.link}>{slinks.name}</Link>
                  </li>
                )}
              </ul>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default NavLinks;

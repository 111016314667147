import Marquee from "react-fast-marquee";
import RegLogo from "../assets/photo/reg.png";
import RwandaAir from "../assets/photo/RwandaAir.png";
import Client1 from "../assets/images/clientImages/client1.png";
import Client2 from "../assets/images/clientImages/client2.png";
import Client3 from "../assets/images/clientImages/client3.png";
import Client4 from "../assets/images/clientImages/client4.png";
import Client5 from "../assets/images/clientImages/client5.png";
import Client6 from "../assets/images/clientImages/client6.png";
import Client7 from "../assets/images/clientImages/client7.png";
import Client8 from "../assets/images/clientImages/client8.png";
import Client9 from "../assets/images/clientImages/client9.jpg";
import Client10 from "../assets/images/clientImages/client10.png";
import Client11 from "../assets/images/clientImages/client11.jpeg";
import Client12 from "../assets/images/clientImages/client12.jpeg";
import Client13 from "../assets/images/clientImages/client13.png";
import Client14 from "../assets/images/clientImages/client14.png";

const PartnerPage = () => {
  const logos = [
    RegLogo,
    RwandaAir,
    Client1,
    Client2,
    Client3,
    Client4,
    Client5,
    Client6,
    Client7,
    Client8,
    Client9,
    Client10,
    Client11,
    Client12,
    Client13,
    Client14,
  ];
  return (
    <section className="pb-14 ">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex flex-col items-center justify-cente ">
        <div className="mb-2 text-center">
          <h2 className="mb-2 text-xl tracking-tight font-semibold text-[#3a98d2]  uppercase">
            OUR Clients
          </h2>
        </div>
        <Marquee pauseOnHover>
          {logos?.map((logo, index) => (
            <img
              key={index}
              src={logo}
              alt={`Logo ${index + 1}`}
              className="h-16 mx-4"
            />
          ))}
        </Marquee>
      </div>
    </section>
  );
};

export default PartnerPage;

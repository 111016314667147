import React, { useState } from "react";
import CountrySelector from "../components/country/selector";
import { COUNTRIES } from "../utils/libs/countries";
import { SelectMenuOption } from "../utils/libs/types";
import { Modal, Select, Button, Form, Input } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import emailjs from "emailjs-com";
import { useTranslation } from "react-i18next";
import { handlerSelectedCountry } from "../utils/helper";
import { sendMessageAction } from "../store/message/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { mymessageActions } from "../store/message";
const course = "service_w1krbwg";
const subCatgry = "template_h1zgx7e";
const catgry = "0SIjHpblaiVX1hF4_";
const { TextArea } = Input;

const ContactPage = () => {
  const { message } = useSelector((state: any) => state);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [country, setCountry] = useState<SelectMenuOption["value"]>("AZ");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState("");
  const handleChange = (value: any) => {
    setPhoneNumber(value);
  };

  const countrySelected = handlerSelectedCountry(country);

  const handleCheck = (data: any) => {
    emailjs
      .send(course, subCatgry, data, catgry)
      .then((response) => {
        console.log("Email sent successfully:", response);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  const handleBack = () => {
    dispatch(mymessageActions.setMessageSubmited(false));
    navigate("/");
  };

  const onFinish = (values: any) => {
    const payload = {
      ...values,
      country: countrySelected,
      phone: phoneNumber,
    };
    sendMessageAction(payload)(dispatch);
    handleCheck(payload);
  };

  return (
    <>
      <section className="bg-white">
        <div className="mx-auto max-w-7xl py-16 lg:py-32 px-4 md:px-0">
          <div className="mb-4 px-2 md:px-0">
            <div className="mb-6 max-w-3xl md:text-center md:mx-auto md:mb-12">
              <h2 className="mb-4 text-2xl md:text-3xl tracking-tight font-semibold md:font-extrabold text-center text-gray-900 mt-20">
                {t("contact")}
              </h2>
              <p className="mx-auto mt-4 max-w-3xl text-lg md:text-xl text-gray-600">
                {t("contactDesc")}
              </p>
            </div>
          </div>
          <div className="flex px-2 md:px-32">
            <div className="flex w-full flex-col md:flex-row">
              <div className="h-full pr-6 md:w-1/2">
                <h2 className="mb-4 text-lg md:text-2xl tracking-tight font-medium text-gray-900">
                  {t("Get in Touch")}
                </h2>
                <ul className="mb-6 md:mb-0">
                  <li className="flex">
                    <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="h-6 w-6"
                      >
                        <path d="M21 5.25L12 12.71 3 5.25M12 12.71L12 2"></path>
                        <path d="M2 5.25h20a2 2 0 0 1 2 2v9.13a1 1 0 0 1-.29.71l-9 8.68a1 1 0 0 1-1.42 0l-9-8.68a1 1 0 0 1-.29-.71v-9.13a2 2 0 0 1 2-2z"></path>
                      </svg>
                    </div>
                    <div className="ml-4 mb-4">
                      <h3 className="mb-2 text-sm md:text-lg font-medium leading-6 text-gray-900 ">
                        {t("Email")}
                      </h3>
                      <p className="text-gray-600 text-sm md:text-lg">
                        nctar.trainingandresearch@gmail.com
                      </p>
                      {/* <p className="text-gray-600 text-sm md:text-lg">
                        nkudone1984@gmail.com
                      </p> */}
                    </div>
                  </li>
                  <li className="flex">
                    <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="h-6 w-6"
                      >
                        <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
                        <path d="M15 7a2 2 0 0 1 2 2"></path>
                        <path d="M15 3a6 6 0 0 1 6 6"></path>
                      </svg>
                    </div>
                    <div className="ml-4 mb-4">
                      <h3 className="mb-2 text-sm md:text-lg font-medium leading-6 text-gray-900 ">
                        {t("Contact")}
                      </h3>
                      <div className="flex space-x-2 text-gray-600 text-sm md:text-lg pb-4">
                        <h1>{t("Mobile")}</h1>
                        <p className="flex flex-col space-y-1 text-sm md:text-lg">
                          {/* <span className="text-sm md:text-lg">
                            +250 788 474 820
                          </span> */}
                          <span className="text-sm md:text-lg">
                            +254 720 530 113
                          </span>
                          {/* <span className="text-sm md:text-lg">
                            +1 (435) 720-9200
                          </span> */}
                        </p>
                      </div>
                      <div className="flex space-x-2 text-gray-600 text-sm md:text-lg">
                        <h1>{t("P.O.Box")}</h1>
                        <p className="flex flex-col space-y-1 text-sm md:text-lg">
                          <span className="text-sm md:text-lg">
                            13913 00100 Nairobi
                          </span>
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="flex">
                    <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="h-6 w-6"
                      >
                        <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                        <path d="M12 7v5l3 3"></path>
                      </svg>
                    </div>
                    <div className="ml-4 mb-4">
                      <h3 className="mb-2 text-sm md:text-lg font-medium leading-6 text-gray-900 ">
                        {t("Working hours")}
                      </h3>
                      <p className="text-gray-600 text-sm md:text-lg">
                        {t("MondayToFriday")}: 08:00 - 17:00
                      </p>
                      <p className="text-gray-600 text-sm md:text-lg">
                        {t("Saturday")} &amp; {t("Sunday")}: 08:00 - 12:00
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="card h-fit w-full px-5">
                <h2 className="mb-4 text-lg md:text-2xl tracking-tight font-medium text-gray-900">
                  {t("Send Us Message")}
                </h2>
                <Form
                  wrapperCol={{ span: 14 }}
                  layout="vertical"
                  style={{ maxWidth: 600 }}
                  onFinish={onFinish}
                >
                  <Form.Item label={t("Title")} name="title">
                    <Select
                      style={{ width: "100%", height: 42 }}
                      showSearch
                      placeholder="Select"
                      optionFilterProp="children"
                      options={[
                        {
                          value: "Mr",
                          label: "Mr",
                        },
                        {
                          value: "Mrs",
                          label: "Mrs",
                        },
                        {
                          value: "Miss",
                          label: "Miss",
                        },
                        {
                          value: "Ms",
                          label: "Ms",
                        },
                        {
                          value: "Rev",
                          label: "Rev",
                        },
                        {
                          value: "Dr",
                          label: "Dr",
                        },
                        {
                          value: "Prefer not to say",
                          label: "Prefer not to say",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t("First Name")}
                    name="fname"
                    rules={[
                      { required: true, message: "First name is required!" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={t("Last Name")}
                    name="lname"
                    rules={[
                      { required: true, message: "Last name is required!" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={t("Your email")}
                    name="email"
                    rules={[{ required: true, message: "Email is required!" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={t("Telephone")}
                    name={"phone"}
                    rules={[
                      { required: true, message: "Telephone is required!" },
                    ]}
                  >
                    <PhoneInput
                      country={"rw"}
                      value={phoneNumber}
                      onChange={handleChange}
                      countryCodeEditable={false}
                      inputProps={{
                        required: true,
                      }}
                      buttonStyle={{
                        height: "40px",
                        border: "1px solid rgba(128, 128, 128, 0.5)",
                        color: "black",
                      }}
                      inputStyle={{
                        height: "40px",
                        width: "100%",
                        border: "1px solid rgba(128, 128, 128, 0.5)",
                        color: "black",
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t("Country")}
                    name="country"
                    rules={[
                      { required: true, message: "Please input your Country!" },
                    ]}
                  >
                    <CountrySelector
                      id={"country-selector"}
                      open={isOpen}
                      onToggle={() => setIsOpen(!isOpen)}
                      onChange={setCountry}
                      selectedValue={COUNTRIES.find(
                        (option) => option.value === country
                      )}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t("Company")}
                    name="company"
                    rules={[
                      { required: true, message: "Company is required!" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="jobtitle"
                    label={t("Job Title")}
                    rules={[
                      { required: true, message: "Job Title is required!" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label={t("Howhear")} name="hearus">
                    <Select
                      style={{ width: "100%", height: 42 }}
                      showSearch
                      placeholder="Select"
                      optionFilterProp="children"
                      options={[
                        {
                          value: "Website",
                          label: "Website",
                        },
                        {
                          value: "Twitter",
                          label: "Twitter",
                        },
                        {
                          value: "Facebook",
                          label: "Facebook",
                        },
                        {
                          value: "Instagram",
                          label: "Instagram",
                        },
                        {
                          value: "LinkedIn",
                          label: "LinkedIn",
                        },
                        {
                          value: "Friends / Colleague",
                          label: "Friends / Colleague",
                        },
                        {
                          value: "College/ University",
                          label: "College/ University",
                        },
                        {
                          value: "Others",
                          label: "Others",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t("subject")}
                    name="subject"
                    rules={[
                      { required: true, message: "Subject is required!" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={t("Your message")}
                    name="message"
                    rules={[
                      { required: true, message: "Message is required!" },
                    ]}
                  >
                    <TextArea
                      placeholder="Message..."
                      autoSize={{ minRows: 2, maxRows: 6 }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      style={{
                        backgroundColor: "#003d97",
                        padding: "6px",
                        borderRadius: "6px",
                        color: "white",
                      }}
                    >
                      <span className="-mt-2">{t("Send message")}</span>
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal open={message?.messageSubmited} footer={null}>
        <>
          <div
            className="bg-green-200 border-green-600 text-green-600 border-l-4 p-4"
            role="alert"
          >
            <p className="font-bold">Success</p>
            <p>Congratulations, your message has been submitted successfully</p>
            <p>We Will get back to you as soon as possible</p>
          </div>
          <div>
            <button
              onClick={handleBack}
              type="button"
              className="text-white outline-none focus:outline-none focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 me-2 my-2 dark:bg-gray-800 bg-gray-700 focus:ring-gray-700 border-gray-700"
            >
              Back
            </button>
          </div>
        </>
      </Modal>
    </>
  );
};

export default ContactPage;

import React, { useState } from "react";
import { Courses } from "../../assets/course/courses";
import { SearchButton } from "../../components/Button/SearchButton";
import { useNavigate, useParams } from "react-router-dom";
import { Select } from "antd";
import { getCoursesByCategory } from "../../utils/helper";
import TopSection from "../../components/TopSection";
import { useTranslation } from "react-i18next";

const CourseByCategory = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<any>([]);

  const { catName } = useParams();

  const handleSearchChange = (e: any) => {
    const query = e?.target?.value;
    setSearchQuery(query);

    const results = Courses.filter((course) =>
      course.title.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(results);
  };

  const filteredCourse = Courses.filter(
    (course) => course.category === catName
  );

  const categoryCounts = getCoursesByCategory(Courses);

  const filteredCategory = categoryCounts.filter(
    (course) => course.category === catName
  );

  const handleChange = (value: string) => {
    navigate(`/programme/${value}`);
  };

  return (
    <div className="bg-white border pt-20 lg:pt-36 text-gray-900">
      {/* <TopSection title={`${catName}`} bgImage={"calendar"} /> */}
      <div className="w-full max-w-[1100px] md:mx-auto md:p-4 md:py-8">
        <div className="text-gray-700 mx-4">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between pb-10">
            <h1 className="py-4 md:py-0">
              <span
                className="text-gray-400 font-normal text-sm cursor-pointer hover:underline hover:font-semibold hover:text-gray-600"
                onClick={() => navigate("/")}
              >
                {" "}
                {t("Home")}
              </span>{" "}
              | <span className="capitalize font-semibold">{catName}</span>
            </h1>
            <div>
              <SearchButton
                handleChange={handleSearchChange}
                searchQuery={searchQuery}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-1 md:pl-8 pb-10 md:pb-0 md:mr-4">
              <h1 className="font-medium md:font-semibold pb-4">
                {t("FILTER BY COURSES")}
              </h1>
              <Select
                defaultValue={`${filteredCategory[0]?.category} (${filteredCategory[0]?.count})`}
                style={{ width: 200 }}
                onChange={handleChange}
              >
                {categoryCounts?.map((el: any, index: number) => (
                  <Select.Option value={el?.category} key={index}>
                    {" "}
                    {`${t(el?.category)} (${el?.count})`}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="flow-root w-full md:w-3/4">
              <ul className="divide-y text-gray-90 divide-gray-600">
                {filteredCourse?.map((item: any) => (
                  <li
                    className="py-4"
                    onClick={() =>
                      navigate(`/programme/${catName}/${item?.id}`)
                    }
                  >
                    <div className="flex items-center cursor-pointer">
                      <div className="flex-1 min-w-0 ms-4">
                        <p className="text-sm text-gray-700 truncate">
                          {t("Coursecode")}: {item.courseCode}
                        </p>
                        <p className="text-sm text-gray-700 truncate">
                          {t(item.category)}
                        </p>
                        <p className="text-lg font-medium md:font-semibold font-sans text-gray-900 truncate">
                          {t(item?.title)}
                        </p>
                        <p className="text-sm font-bold truncate">{`$${item?.courseFess?.toLocaleString()}`}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseByCategory;

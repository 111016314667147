import { notification } from "antd";
import { mymessageActions } from ".";
import { sendMessageServiceUser } from "./services";

export const sendMessageAction = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(mymessageActions.setIsFetching(true));
      const res = await sendMessageServiceUser(data);
      if (res?.status === 201) {
        dispatch(mymessageActions.setMessageSubmited(true));
        dispatch(mymessageActions.setNew(res));
        dispatch(mymessageActions.setIsFetching(false));
        notification.success({ message: "Message Sent Succesfully" });
      }
      dispatch(mymessageActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

import React from "react";
import { useTranslation } from "react-i18next";
import BankTransfer from "../../assets/photo/bankTransfer.jpg";
// import {
//   DownloadOutlined,
//   RotateLeftOutlined,
//   RotateRightOutlined,
//   SwapOutlined,
//   ZoomInOutlined,
//   ZoomOutOutlined,
// } from "@ant-design/icons";
// import { Image, Space } from "antd";

// const src =
//   "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png";

const BankDetails = () => {
  const { t } = useTranslation();
  //   const onDownload = () => {
  //     fetch(src)
  //       .then((response) => response.blob())
  //       .then((blob) => {
  //         const url = URL.createObjectURL(new Blob([blob]));
  //         const link = document.createElement("a");
  //         link.href = url;
  //         link.download = "image.png";
  //         document.body.appendChild(link);
  //         link.click();
  //         URL.revokeObjectURL(url);
  //         link.remove();
  //       });
  //   };

  return (
    <div className="w-full max-w-[1100px] md:mx-auto px-2 md:p-4 md:py-8 text-gray-800 pt-8 uppercase font-semibold">
      <img src={BankTransfer} alt="" className="hidden md:flex" />
      <div className="md:hidden">
        <h2 className="mb-2 text-xl md:text-2xl font-serif font-bold text-center text-[#3a98d2] lg:w-6/12 xl:w-8/12 flex items-center justify-center mx-auto uppercase">
          {t("DirectBanktransfer")}
        </h2>
        <div className=" mx-6 py-10 text-left">
          <div className="flex flex-col md:flex-row gap-6">
            <div>
              <h6 className="mb-4 flex font-bold uppercase md:justify-start underline">
                Equity Bank
              </h6>
              <p className="mb-2 flex">
                <span className="font-semibold">{t("Country")}: RWANDA</span>
              </p>
              <p className="mb-2 flex">
                <span className="font-semibold">
                  {t("Bankname")}: EQUITY Bank RWANDA PLC{" "}
                </span>
              </p>
              <p className="mb-2 flex md:justify-start">
                <span className="font-semibold">Branch: Rubangura Branch</span>
              </p>
              <p className="mb-2 flex">
                <span className="font-semibold">
                  {t("Adress")}: KN4 AVE, GRAND PENSION PLAZA P.O.BOX 494
                  Kigali, RWANDA
                </span>
              </p>
              <p className="mb-2 flex md:justify-start">
                <span className="font-semibold">
                  {t("AccountNumber")}: 4024201077580
                </span>
              </p>
              <p className="mb-2 flex md:justify-start">
                <span className="font-semibold">
                  {t("Accountname")}: NCTAR Global Consultants Ltd
                </span>
              </p>
              <p className="mb-2 flex md:justify-start">
                <span className="font-semibold">
                  {t("Currency")}: UNITED STATES DOLLAR
                </span>
              </p>
              <p className="mb-4 flex md:justify-start">
                <span className="font-semibold">SWIFT CODE: EQBLRWRW</span>
              </p>

              <h6 className="mb-2 flex font-semibold md:justify-start text-[#3a98d2]">
                {t("INTERMEDIARYBANKDETAILS")}
              </h6>
              <p className="mb-2 flex md:justify-start">
                <span className="font-semibold">{t("name")}: CITI BANK</span>
              </p>
              <p className="mb-2 flex md:justify-start">
                <span className="font-semibold">SWIFT CODE: CITIUS33</span>
              </p>
              <p className="mb-2 flex md:justify-start">
                <span className="font-semibold">
                  {t("Adress")}: NEW YORK, USA{" "}
                </span>
              </p>
              {/* <div>
              <Image
                width={50}
                src={src}
                preview={{
                  toolbarRender: (
                    _,
                    {
                      transform: { scale },
                      actions: {
                        onFlipY,
                        onFlipX,
                        onRotateLeft,
                        onRotateRight,
                        onZoomOut,
                        onZoomIn,
                      },
                    }
                  ) => (
                    <Space size={12} className="toolbar-wrapper">
                      <DownloadOutlined onClick={onDownload} />
                      <SwapOutlined rotate={90} onClick={onFlipY} />
                      <SwapOutlined onClick={onFlipX} />
                      <RotateLeftOutlined onClick={onRotateLeft} />
                      <RotateRightOutlined onClick={onRotateRight} />
                      <ZoomOutOutlined
                        disabled={scale === 1}
                        onClick={onZoomOut}
                      />
                      <ZoomInOutlined
                        disabled={scale === 50}
                        onClick={onZoomIn}
                      />
                    </Space>
                  ),
                }}
              />
            </div> */}
            </div>
            <div>
              <h6 className="mb-4 flex font-bold uppercase md:justify-start underline">
                Bank of Kigali
              </h6>
              <p className="mb-2 flex md:justify-start">
                <span className="font-semibold">{t("Country")}: RWANDA</span>
              </p>
              <p className="mb-2 flex md:justify-start">
                <span className="font-semibold">
                  {t("Bankname")}: BANK OF KIGALI PlC
                </span>
              </p>
              <p className="mb-2 flex md:justify-start">
                <span className="font-semibold">
                  branch: Headquarters, Kigali
                </span>
              </p>
              <p className="mb-2 flex md:justify-start">
                <span className="font-semibold">
                  {t("Adress")}: Kigali-Rwanda
                </span>
              </p>
              <p className="mb-2 flex md:justify-start">
                <span className="font-semibold">
                  {t("AccountNumber")}: 100157165288
                </span>
              </p>
              <p className="mb-2 flex md:justify-start">
                <span className="font-semibold">
                  {t("Accountname")}: NCTAR Global Consultants Ltd
                </span>
              </p>
              <p className="mb-2 flex md:justify-start">
                <span className="font-semibold">
                  {t("Currency")}: Rwandan Francs (RWF)
                </span>
              </p>
              <p className="mb-2 flex md:justify-start">
                <span className="font-semibold">SWIFT CODE: BKIGRWRW</span>
              </p>
              <p className="mb-2 flex md:justify-start">
                <span className="font-semibold">
                  Iban Number: RW08040100157165288646
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankDetails;

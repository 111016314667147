import { useTranslation } from "react-i18next";

const WelcomePage = () => {
  const { t } = useTranslation();
  return (
    <div className="block rounded">
      <div className="flex flex-wrap items-center relative">
        <div className="w-full shrink-0 grow-0 basis-auto xlg:w-6/12 xlx:w-8/12 text-white lg:py-10 pb-4 flex items-center justify-center mx-auto">
          <div className="px-6 md:px-6">
            <h2 className="mb-2 text-xl md:text-2xl font-serif font-bold text-center text-[#3a98d2] lg:w-6/12 xl:w-8/12 flex items-center justify-center mx-auto">
              {t("welcometoNCTARTrainingConsultants")}
            </h2>
            <p className="md:font-normal lg:text-lg text-left text-gray-800 pt-6">
              {t("welcometoNCTARTrainingConsultantsDesc1")}
              <br /> <br />
              {t("welcometoNCTARTrainingConsultantsDesc2")}{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;

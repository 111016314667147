import React from "react";
import { useTranslation } from "react-i18next";

const CompanyDescription = () => {
  const { t } = useTranslation();
  return (
    <div className="block md:rounded bg-[#3a98d2] md:mx-4">
      <div className="flex flex-wrap items-center relative">
        <div className="w-full shrink-0 grow-0 basis-auto lg:w-6/12 xl:w-8/12 text-white lg:py-10 py-4 flex items-center justify-center mx-auto">
          <div className="px-6 md:px-12">
            <h2 className="mb-2 text-xl md:text-3xl font-medium text-primary text-center">
              {t("companyDescription")}
            </h2>
            {/* <p className="mb-4 font-semibold text-xl text-center">
              What your business needs
            </p> */}
            {/* <p className="md:font-normal text-justify">
              <span className="text-sm md:text-xl font-semibold">
                NCTAR Training Consultants
              </span>{" "}
              offers a wide range of consulting services with the necessary
              tools and expertise to help grow your business. We partner with
              our clients from start to finish, focusing on thier needs while
              producing new ideas, developing effective strategies and designing
              high quality and scalable solutions. Contact us to learn more.
              <br />
              <span className="text-sm md:text-xl font-semibold">
                NCTAR Training Consultants
              </span>{" "}
              is registered in RWANDA under article 24 of Law No 17/2018 of
              13/04/2018 governing companies in Rwanda.
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDescription;

import React from "react";

const TopSection: React.FC<{
  title: string;
  content?: string;
  bgImage?: any;
}> = ({ title, content, bgImage }) => {
  return (
    <div
      className={`h-52 md:h-96 bg-no-repeat bg-cover w-full ${
        bgImage ? bgImage : "hero"
      }`}
    >
      <div className="flex items-center justify-center h-full text-white">
        <div className="w-4/5 lg:w-1/2">
          <h1 className="text-center text-2xl md:text-5xl font-bold pb-2">
            {title}
          </h1>
          <p className="text-center text-xl font-medium text-gray-200">
            {content}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TopSection;

// import Image1 from "../../assets/photo/11111.jpeg";
// import TracyImage from "../../assets/photo/3333.jpeg";
// import Trainer from "../../assets/images/trainer.jpg";
// import Marketing from "../../assets/photo/0000.jpg";
// import Advisor from "../../assets/photo/44444.jpg";
// import MDirector from "../../assets/images/director.jpg";
import { Divider } from "antd";
import Const1 from "../../assets/constants/cons1.jpg";
import TopSection from "../../components/TopSection";
import { useTranslation } from "react-i18next";
//

const Team = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#e4e4e4] pt-20 lg:pt-36 pb-10 border">
      <TopSection
        title={t("MeetourTeam")}
        content={t("MeetourTeamDesc")}
        bgImage={"ourTeam"}
      />
      <div className="w-full max-w-[1100px] md:mx-auto md:p-4 md:py-8">
        <div className="text-gray-700 mt-6 lg:mt-20 space-y-8">
          {/* <div className="flex flex-col bg-[#ffffff] md:flex-row md:max-w-6xl mx-6 md:mx-0 pt-10">
            <div className=" pb-10 w-full md:w-[3000px] px-[20px]">
              <img
                className="object-cover w-[400px] md:rounded-none"
                src={MDirector}
                alt=""
              />
            </div>
            <div className="flex flex-col xw-[100%] pb-6 leading-normal px-6">
              <h5 className="mb-2 text-2xl font-medium tracking-tight text-[#003d8f]">
                Donat NKURIKIYINTWALI
              </h5>
              <h6 className="mb-2 text-2xl font-medium tracking-tight pb-4">
                {t("DonatRole")}
              </h6>
              <p className="mb-3 font-normal text-gray-700">
                {t("DonatSummary1")}
                <br />
                <br />
                {t("DonatSummary2")}
                <br />
                <br />
              </p>
            </div>
          </div> */}
          {/* <div className="flex flex-col bg-[#ffffff] md:flex-row md:max-w-6xl mx-6 md:mx-0 pt-10">
            <div className="md:px-10 md:w-[1500px] pb-10 w-full px-[20px]">
              <img
                className="object-cover w-full md:rounded-none"
                src={Advisor}
                alt=""
              />
            </div>
            <div className="flex flex-col  pb-6 leading-normal px-6">
              <h5 className="mb-2 text-2xl font-medium tracking-tight text-[#003d8f]">
                Kirsten Fagerback
              </h5>
              <h6 className="mb-2 text-2xl font-medium tracking-tight pb-4">
                {t("KirstenRole")}
              </h6>
              <p className="mb-3 font-normal text-gray-700">
                {t("KirstenSummary1")}
                <br />
                {t("KirstenSummary2")}
              </p>
            </div>
          </div> */}
          <div className="flex flex-col bg-[#ffffff] rounded-md shadow-inner md:flex-row md:max-w-6xl md:mx-0 pt-10">
            <div className="md:px-10 xmd:w-4/5 pb-10 w-full px-[20px]">
              <img
                className="object-cover w-full xmd:h-auto md:rounded-none"
                src={Const1}
                alt=""
              />
            </div>
            <div className="flex flex-col pb-6 leading-normal px-6 w-[100%]">
              <h5 className="mb-2 text-2xl font-medium tracking-tight text-[#003d8f]">
                Mr. NYARWARI EVANS MAGOMA
              </h5>
              <h6 className="text-2xl font-medium tracking-tight">
                {t("EdgerOgaoRole")}
              </h6>
              <Divider />
              <div className="p-2 max-w-4xl mx-auto">
                {/* <h1 className="text-3xl font-bold text-center mb-8">
                 
                </h1> */}
                <p className="text-lg mb-4">
                  Dear Valued Partners and Clients,
                </p>
                <p className="text-lg mb-4">
                  Welcome to NCTAR Global Consultants Ltd.
                </p>
                <p className="text-lg mb-4">
                  Since our inception in 2018, we have dedicated ourselves to
                  being a transformative force in the business consulting and
                  training landscape. At NCTAR Global Consultants, our core
                  mission is to empower organizations and their leaders with
                  cutting-edge training, innovative consulting solutions, and
                  unwavering support.
                </p>
                <p className="text-lg mb-4">
                  Our vision is to stand as the premier partner in management
                  consultancy, recognized for our commitment to excellence and
                  client-centric solutions. We are driven by the pursuit of
                  innovation and the relentless pursuit of growth for every
                  business we engage with.
                </p>
                <p className="text-lg mb-4">
                  We offer a diverse range of services designed to meet the
                  evolving needs of our clients. From intensive short courses in
                  various disciplines to comprehensive consultancy services, our
                  goal is to deliver impactful solutions that foster growth and
                  success. Our training programs, which span across global hubs
                  such as Dubai, Doha, Nairobi, and Kigali, are crafted to
                  provide immediate, applicable knowledge and skills.
                </p>
                <p className="text-lg mb-4">
                  At NCTAR Global Consultants, we pride ourselves on our
                  exceptional client service, evidenced by our high levels of
                  client satisfaction and repeat business. Our team of experts,
                  blending practical expertise with scholarly insights, is
                  committed to helping you unlock your organization’s full
                  potential.
                </p>
                <p className="text-lg mb-4">
                  Thank you for choosing NCTAR Global Consultants. We look
                  forward to the opportunity to partner with you and drive
                  transformative growth within your organization.
                </p>
                <p className="text-lg mb-4 font-bold text-[#003d8f]">
                  Warm regards,
                </p>
                <p className="text-lg font-bold text-[#003d8f]">
                  Evans Nyarwari Magoma
                </p>
                <p className="text-lg">Chief Executive Officer</p>
                <p className="text-lg">NCTAR Global Consultants Ltd</p>
              </div>
            </div>
          </div>
          {/* <div className="flex flex-col xitems-center bg-[#ffffff] md:flex-row md:max-w-6xl mx-6 md:mx-0 pt-10">
            <div className="md:px-10 md:w-[1600px] pb-10 w-full px-[20px]">
              <img
                className="object-cover w-full xmd:h-auto md:rounded-none"
                src={TracyImage}
                alt=""
              />
            </div>
            <div className="flex flex-col pb-6 leading-normal px-6">
              <h5 className="mb-2 text-2xl font-medium tracking-tight text-[#003d8f]">
                Tracy Mumbua
              </h5>
              <h6 className="mb-2 text-2xl font-medium tracking-tight pb-4">
                {t("TracyMumbuaRole")}
              </h6>
              <p className=" font-normal text-gray-700">
                {t("TracyMumbuaSummary1")}
                <br />
                {t("TracyMumbuaSummary2")}
                <br />
                <br />
                {t("TracyMumbuaSummary3")}
              </p>
            </div>
          </div>
          <div className="flex flex-col xitems-center bg-[#ffffff] md:flex-row md:max-w-6xl mx-6 md:mx-0 pt-10">
            <div className="md:px-10 md:w-[2000px] pb-10 w-full px-[20px]">
              <img
                className="object-cover w-full md:rounded-none"
                src={Image1}
                alt=""
              />
            </div>
            <div className="flex flex-col xjustify-between pb-6 leading-normal px-6">
              <h5 className="mb-2 text-2xl font-medium tracking-tight text-[#003d8f]">
                RITA PETER
              </h5>
              <h6 className="mb-2 text-2xl font-medium tracking-tight pb-4">
                {t("RITAPETERRole")}
              </h6>
              <p className="mb-3 font-normal text-gray-700">
                {t("RITAPETERSummary1")}
                <br />
                <br />
                {t("RITAPETERSummary2")}
                <br />
                <br />
                {t("RITAPETERSummary3")}
                <br />
                <br />
                {t("RITAPETERSummary4")}
              </p>
            </div>
          </div>

          <div className="flex flex-col xitems-center bg-[#ffffff] md:flex-row md:max-w-6xl mx-6 md:mx-0 pt-10">
            <div className="md:px-10 md:w-[2000px] pb-10 w-full px-[20px]">
              <img
                className="object-cover w-full md:rounded-none"
                src={Marketing}
                alt=""
              />
            </div>
            <div className="flex flex-col xjustify-between pb-6 leading-normal px-6">
              <h5 className="mb-2 text-2xl font-medium tracking-tight text-[#003d8f]">
                Jane Onywere
              </h5>
              <h6 className="mb-2 text-2xl font-medium tracking-tight pb-4">
                {t("JaneOnywereRole")}
              </h6>
              <p className="mb-3 font-normal text-gray-700">
                {t("JaneOnywereSummary1")}
                <br />
                <br />
                {t("JaneOnywereSummary2")}
                <br />
                <br />
                {t("JaneOnywereSummary3")}
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default Team;

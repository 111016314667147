import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import RegImage1 from "../../assets/images/regStuff/Reg1.jpg";
import RegImage2 from "../../assets/images/regStuff/Reg2.jpg";
import RegImage3 from "../../assets/images/regStuff/Reg3.jpg";
import RegImage4 from "../../assets/images/regStuff/Reg4.jpg";
import RegImage5 from "../../assets/images/regStuff/Reg5.jpg";
import RegImage6 from "../../assets/images/regStuff/Reg6.jpg";
import RegImage7 from "../../assets/images/regStuff/Reg7.jpg";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../../assets/styles/slider.css";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const Slider = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, []);
  const images = [
    RegImage1,
    RegImage4,
    RegImage2,
    RegImage3,
    RegImage5,
    RegImage7,
    RegImage6,
  ];

  const progressCircle = useRef<any>(null);
  const progressContent = useRef<any>(null);

  return (
    <div className="relative">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        loop={true}
        effect={"fade"}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        onSlideChange={(swiper: any) => setActiveIndex(swiper.realIndex)}
        onInit={(swiper: any) => setActiveIndex(swiper.realIndex)}
        className="mySwiper"
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 2,
          },
        }}
      >
        {images?.map((image, index) => (
          <SwiperSlide key={index}>
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              className=" lg:h-[800px] w-full object-contain bg-[#dfdad6] rounded-md"
            />
            {activeIndex !== index && (
              <div className="absolute inset-0 bg-black opacity-55 w-full"></div>
            )}
          </SwiperSlide>
        ))}

        <div className="autoplay-progress" slot="container-end">
          <svg viewBox="0 0 48 48" ref={progressCircle}>
            <circle cx="24" cy="24" r="20"></circle>
          </svg>
          <span ref={progressContent}></span>
        </div>
      </Swiper>
    </div>
  );
};
export default Slider;

export const faqLeft = [
  {
    id: 1,
    title:
      "Who are we?",
    content:
      " LCT International is the UK's leading corporate training provider, offering over 150 short courses (3 days - 3 weeks) in the following subject areas: Advanced Management; Leadership; Law; Oil & Gas; Digital; Operations Management; Finance; Human Resources; Public Relations; Sales; Compliance; Sustainability; and Employee Well Being.",
  },
  {
    id: 2,
    title:
      "Which markets do you operate in?",
    content:
      " We serve numerous markets worldwide including Europe, the Middle East, Africa and Asia with clients operating in the following sectors:",
  },
  {
    id: 3,
    title:
      "Do you offer training overseas?",
    content:
      " ",
  },
  {
    id: 3,
    title:
      "Do you offer tailor-made training?",
    content:
      " ",
  },
  {
    id: 3,
    title:
      "Do you offer training overseas?",
    content:
      " ",
  },
  {
    id: 3,
    title:
      "Do you offer training overseas?",
    content:
      " ",
  },
   {
    id: 3,
    title:
      "Do you offer training overseas?",
    content:
      " ",
  }, 
  {
    id: 3,
    title:
      "Do you offer training overseas?",
    content:
      " ",
  },
   {
    id: 3,
    title:
      "Do you offer training overseas?",
    content:
      " ",
  },
   {
    id: 3,
    title:
      "Do you offer training overseas?",
    content:
      " ",
  }, 
  {
    id: 3,
    title:
      "Do you offer training overseas?",
    content:
      " ",
  }, 
  {
    id: 3,
    title:
      "Do you offer training overseas?",
    content:
      " ",
  }, 
  {
    id: 3,
    title:
      "Do you offer training overseas?",
    content:
      " ",
  },
];

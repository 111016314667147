import React from "react";
import CourseCard from "../components/cards/CourseCard";
// import TestimonialCard from "../components/cards/TestimonialCard";
import CompanyDescription from "../components/CompanyDescription";
import WorkshopDetails from "../components/cards/Workshop";
import Slider from "../components/sliders/Slider";
import WelcomePage from "./WelcomePage";
import { CourseByAllocation } from "../assets/course/courseByCategory";
import { useTranslation } from "react-i18next";
import OurService from "./service/OurService";
import BankDetails from "../components/layout/BankDetails";
import PartnerPage from "./PartnerPage";

const HomePage = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="bg-white text-gray-600">
        <div className="relative mx-auto">
          <Slider />
        </div>
        <div className="bg-[#fafafa] opacity-90 pt-6">
          <PartnerPage />
        </div>
        <div className="w-full max-w-[1100px] md:mx-auto md:p-4">
          <CompanyDescription />
          <div className="md:mx-4 mt-6 mb-14 flex flex-col">
            <WorkshopDetails />
          </div>
        </div>
        <div className="bg-[#fafafa] opacity-90 pt-6">
          <div className="w-full max-w-[1100px] md:mx-auto md:p-4">
            <WelcomePage />
          </div>
        </div>

        <div className="w-full max-w-[1100px] md:mx-auto md:p-4">
          <OurService />
          <div className="mt-10 mb-2 flex flex-col">
            <h2 className="mt-6 px-4 pb-20 text-xl text-gray-800 font-font-semibold text-center">
              {t("discoverRangeTrainingCourses")}
            </h2>
            <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 md:gap-16">
              {CourseByAllocation?.map((d: any) => (
                <CourseCard data={d} />
              ))}
            </div>
          </div>
          {/* <TestimonialCard /> */}
        </div>
      </div>
      <BankDetails />
    </>
  );
};

export default HomePage;

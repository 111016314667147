import TwitterLogo from "../../assets/photo/twitterLogo.png";
import { Link } from "react-router-dom";
import { CourseByAllocation } from "../../assets/course/courseByCategory";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full max-w-[1100px] md:mx-auto px-6 md:p-4 md:py-8 border">
      <footer className="bg-white text-center text-neutral-600 lg:text-left">
        <div className="flex items-center justify-center border-b-2 border-neutral-200 p-6 lg:justify-between">
          <div className="mr-12 hidden lg:block">
            <span>{t("getconnected")}</span>
          </div>
          <div className="flex justify-center">
            <a
              href="https://www.facebook.com/profile.php?id=61555173747074"
              target="_blank"
              rel="noreferrer"
              className="mr-6 text-neutral-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
              </svg>
            </a>
            <a
              href="https://x.com/NCTAR2020?t=awFUt0Gd-8GJUCO3zic1KQ&s=08"
              target="_blank"
              rel="noreferrer"
              className="mr-6 text-neutral-600"
            >
              <img src={TwitterLogo} alt="" className="h-4 w-4" />
            </a>
            <a
              href="https://www.instagram.com/nctar_training/"
              target="_blank"
              rel="noreferrer"
              className="mr-6 text-neutral-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
              </svg>
            </a>
            <Link to={""} className="mr-6 text-neutral-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
              </svg>
            </Link>
          </div>
        </div>

        <div className="mx-6 py-10 text-left">
          <div className="grid-1 grid gap-8 md:grid-cols-2 lg:grid-cols-4">
            <div className="">
              <h6 className="mb-4 flex font-semibold uppercase md:justify-start">
                {t("programmes")}
              </h6>
              {CourseByAllocation?.map((item: any) => (
                <p className="mb-4">
                  <Link
                    to={`/programme/${item?.category}`}
                    className="text-neutral-600"
                  >
                    {t(item?.category)}
                  </Link>
                </p>
              ))}
            </div>
            <div className="">
              <h6 className="mb-4 flex font-semibold uppercase md:justify-start">
                {t("about")}
              </h6>
              <p className="mb-4">
                <Link to={"/about"} className="text-neutral-600">
                  {t("aboutUs")}
                </Link>
              </p>
              <p className="mb-4">
                <Link to={"/about/team"} className="text-neutral-600">
                  {t("ourTeam")}
                </Link>
              </p>
              <p className="mb-4">
                <Link
                  to={"/about/training/consultant"}
                  className="text-neutral-600"
                >
                  {t("trainingConsultant")}
                </Link>
              </p>
              <p className="mb-4">
                <Link to={"/about/vanues"} className="text-neutral-600">
                  {t("vanues")}
                </Link>
              </p>
              <p>
                {/* <Link to={"/about/testimonials"} className="text-neutral-600">
                  Testimonials
                </Link> */}
              </p>
            </div>
            <div className="">
              <h6 className="mb-4 flex font-semibold uppercase md:justify-start">
                {t("info")}
              </h6>
              <p className="mb-4">
                <Link to={"/infomation/visa"} className="text-neutral-600">
                  {t("vISAApplication")}
                </Link>
              </p>
              <p className="mb-4">
                <Link
                  to={"/infomation/programme/alocation"}
                  className="text-neutral-600"
                >
                  {t("courseAllocations")}
                </Link>
              </p>
              <p className="mb-4">
                <Link
                  to={"/programme/Management Skills"}
                  className="text-neutral-600"
                >
                  {t("termsandconditions")}
                </Link>
              </p>
              <p className="mb-4">
                <Link to={"/infomation/policies"} className="text-neutral-600">
                  {t("policies")}
                </Link>
              </p>
              {/* <p>
                <Link to={"/infomation/faq"} className="text-neutral-600">
                  FAQ
                </Link>
              </p> */}
            </div>
            <div>
              <h6 className="mb-4 flex font-semibold uppercase md:justify-start">
                {t("trainingHubs")}
              </h6>
              {/* <p className="mb-4 flex md:justify-start">
                <span className="font-semibold">Doha: </span>
                <div className="flex flex-col pl-1">
                  <span>Shaza Doha Hotel</span>
                </div>
              </p>
              <p className="mb-4 flex md:justify-start">
                <span className="font-semibold">Dubai: </span>
                <div className="flex flex-col pl-1">
                  <span>Holiday Inn Dubai</span>
                </div>
              </p> */}
              <p className="mb-4 flex md:justify-start">
                <span className="font-semibold">Nairobi: </span>
                <div className="flex flex-col pl-1">
                  <span>The Drexel House Kenya</span>
                </div>
              </p>
              <p className="mb-4 flex md:justify-start">
                <span className="font-semibold">Kigali: </span>
                <div className="flex flex-col pl-1">
                  <span>Dove Hotel</span>
                </div>
              </p>
            </div>
            <div>
              <h6 className="mb-4 flex font-semibold uppercase md:justify-start">
                {t("contact")}
              </h6>
              <p className="mb-4 flex md:justify-start">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="mr-3 h-5 w-5"
                  >
                    <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
                    <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
                  </svg>
                </div>
                <div className="flex flex-col space-y-4">
                  {/* <div className="flex flex-col">
                    <span>{t("kigaliOffice")}</span>
                    <span>{t("kigaliOfficeDesc")}</span>
                  </div> */}
                  <div className="flex flex-col">
                    <span>{t("kenyaOffice")}</span>
                    <span>{t("kenyaOfficeDesc")}</span>
                  </div>
                </div>
              </p>
              <p className="mb-4 flex md:justify-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="mr-3 h-5 w-5"
                >
                  <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                  <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                </svg>
                <div className="flex flex-col">
                  <span className="w-32">
                    nctar.trainingandresearch@gmail.com
                  </span>
                  {/* <span>nkudone1984@gmail.com</span> */}
                </div>
              </p>
              <p className="mb-4 flex xitems-center md:justify-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="mr-3 h-5 w-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                    clipRule="evenodd"
                  />
                </svg>
                <div className="flex flex-col">
                  {/* <span>+250 788 474 820</span> */}
                  <span>+254 720 530 113</span>
                  {/* <span>+1 (435) 720-9200</span> */}
                </div>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 p-6 text-center">
          © 2018 - {new Date().getFullYear()} nctar.org,{" "}
          {t("AllRightsReserved")}
        </div>
      </footer>
    </div>
  );
};

export default Footer;

import React, { useState } from "react";
import CourseCard from "../../components/cards/CourseCard";
import Underline from "../../components/Underline";
import { SearchButton } from "../../components/Button/SearchButton";
import CourseCategory from "../../components/CourseCategory";
import CompanyDescription from "../../components/CompanyDescription";
import { Courses } from "../../assets/course/courses";
import { Pagination } from "antd";
import { useTranslation } from "react-i18next";
// import CourseSlider from "../components/cards/CourseSlider";

const CoursePage = () => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const filteredCourses =
    selectedCategory === "all"
      ? Courses
      : Courses.filter((course) => course.category === selectedCategory);

  const coursesForPage = filteredCourses.slice(startIndex, endIndex);
  const searchCoursesForPage =
    searchResults?.length > 0 && searchResults.slice(startIndex, endIndex);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleCategory = (values: string) => {
    setSelectedCategory(values);
    setCurrentPage(1);
  };

  const handleSearchChange = (e: any) => {
    const query = e?.target?.value;
    setSearchQuery(query);

    const results = Courses.filter((course) =>
      course.title.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(results);
  };

  return (
    <div className="bg-[#e4e4e4] md:mx-4">
      <div className="w-full max-w-[1100px] md:mx-auto md:p-4 md:py-8">
        {/* //banner */}
        {/* <div className="pt-20"> */}
        {/* </div> */}
        <div className="text-gray-700 mx-4 lg:mt-20">
          {/* <CourseSlider /> */}
          <CompanyDescription />
          <div className="md:mx-4 my-20 flex flex-col">
            <h1 className="mb-6 text-xl md:text-3xl font-bold text-center">
              {t("Courses")}
            </h1>
            <Underline />

            <div className="flex items-end justify-end mb-4">
              {" "}
              <SearchButton
                handleChange={handleSearchChange}
                searchQuery={searchQuery}
              />
            </div>

            <div className="flex flex-col md:flex-row">
              <div className="flex-1">
                {searchResults?.length > 0 ? (
                  <div className="xml-10 xmd:ml-0 grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
                    {searchCoursesForPage?.map((d: any) => (
                      <CourseCard data={d} />
                    ))}
                  </div>
                ) : (
                  <div className="xml-10 xmd:ml-0 grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
                    {coursesForPage?.map((d: any) => (
                      <CourseCard data={d} />
                    ))}
                  </div>
                )}
                <div className="flex items-center justify-center mt-20">
                  <Pagination
                    current={currentPage}
                    onChange={handlePageChange}
                    total={
                      searchResults?.length > 0
                        ? searchResults?.length
                        : filteredCourses.length
                    }
                    pageSize={itemsPerPage}
                  />
                </div>
              </div>
              <div className="md:w-96 md:mt-0 mt-10">
                <CourseCategory
                  handleCategory={handleCategory}
                  selectedCategory={selectedCategory}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursePage;

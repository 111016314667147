import React from "react";
import { useTranslation } from "react-i18next";

const OurService = () => {
  const { t } = useTranslation();
  const ourService = [
    {
      serviceTitle: "serviceTitle1",
      service: [
        "Ourservicescat2_10",
        "Ourservicescat2_9",
        "Ourservicescat1_1",
        "Ourservicescat1_2",
        "Ourservicescat1_3",
        "Ourservicescat1_4",
        "Ourservicescat1_5",
        "Ourservicescat1_6",
        "Ourservicescat1_7",
        "Ourservicescat1_8",
        "Ourservicescat1_9",
        "Ourservicescat1_10",
        "Ourservicescat1_11",
      ],
    },
    {
      serviceTitle: "serviceTitle2",
      service: [
        "Ourservicescat2_1",
        "Ourservicescat2_2",
        "Ourservicescat2_3",
        "Ourservicescat2_4",
        "Ourservicescat2_5",
        "Ourservicescat2_6",
        "Ourservicescat2_7",
        "Ourservicescat2_8",
      ],
    },
  ];
  return (
    <div className="px-4 mx-auto max-w-screen-xl lg:px-6">
      <div className="max-w-screen-md mb-8">
        <h2 className="mb-2 text-xl tracking-tight font-semibold text-gray-600 uppercase">
          {t("Ourservices")}
        </h2>
      </div>
      <div className="space-y-4 md:gap-4">
        {ourService?.map((item: any) => (
          <>
            <div>
              <h1 className="text-lg md:text-xl font-medium">
                {t(item?.serviceTitle)}
              </h1>
            </div>
            {item?.service?.map((el: any) => (
              <div className="flex ite">
                <div className="mr-2 mt-1.5">
                  <svg
                    className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 12"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M1 5.917 5.724 10.5 15 1.5"
                    />
                  </svg>
                </div>
                <h3 className="md:text-sm font-medium"> {t(el)}</h3>
              </div>
            ))}
          </>
        ))}
      </div>
    </div>
  );
};

export default OurService;
